<template>
  <Parent>
    <template slot="content">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-row align="center">
              <v-spacer></v-spacer>

              <v-col cols="auto">
                <v-btn
                  color="primary"
                  text
                  class="text-capitalize font-weight-regular"
                  :to="{ name: 'FaqCreate' }"
                >
                  <v-icon left>mdi-plus</v-icon>
                  {{ $t("page_faq_create_title") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="px-0">
            <Table
              v-if="pagination && faqs"
              :headers="headers"
              :items="faqs"
              :loading="loading"
              :total-records="pagination.records_total"
              :number-of-pages="pagination.total_pages"
              @update:options="updateTable"
            >
              <template v-slot:item.id="{ item }">
                <span class="primary--text">{{ item.id }}</span>
              </template>

              <template v-slot:item.category="{ item }">
                <span class="text-capitalize" v-if="item.category">{{
                  item.category.name
                }}</span>
              </template>

              <template v-slot:item.question="{ item }">
                <span class="text-capitalize">{{ item.question }}</span>
              </template>

              <template v-slot:item.answer="{ item }">
                <span
                  class="text-editor-txt truncate-lines lines-1"
                  v-html="item.answer"
                ></span>
              </template>

              <template v-slot:item.order="{ item }">
                <span class="text-capitalize">{{ item.order }}</span>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn icon color="secondary" text :to="`/faq/${item.id}/edit`">
                  <v-icon size="12">$edit</v-icon>
                </v-btn>
                <v-icon
                  @click="
                    deleteItem = item;
                    deleteItemDlg = true;
                  "
                >
                  mdi-delete
                </v-icon>
              </template>
            </Table>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Delete Model -->
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="500"
        v-model="deleteItemDlg"
      >
        <v-card>
          <v-toolbar color="primary" dark>
            {{ $t("confirmation") }}
          </v-toolbar>
          <v-card-text>
            <v-row class="text-h7 pa-3 pt-7">
              {{ $t("page_faqs_edit_confirmation_message") }}
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              :disabled="formStatus"
              color="primary"
              class="mr-3"
              @click="deleteItemConfirm"
              >{{ $t("confirm") }}</v-btn
            >
            <v-btn
              :disabled="formStatus"
              color="red"
              dark
              @click="deleteItemDlg = false"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </Parent>
</template>

<script>
import Parent from "@/views/admin/Setting/Faq/index";
import Table from "@/components/admin/partials/Table/Table";

export default {
  name: "index",
  components: { Table, Parent },
  created() {
    this.getDataFromApi();
  },

  computed: {
    faqs() {
      return this.$store.getters.allFaqs;
    },
    pagination() {
      return this.$store.getters.pagination;
    }
  },

  data() {
    return {
      headers: [
        { text: this.$t("id"), align: "center", value: "id" },
        {
          text: this.$t("page_faqs_table_header_category"),
          align: "center",
          value: "category",
          sortable: false
        },
        {
          text: this.$t("page_faqs_table_header_question"),
          align: "center",
          value: "question",
          sortable: false
        },
        {
          text: this.$t("page_faqs_table_header_answer"),
          align: "center",
          value: "answer",
          sortable: false
        },
        {
          text: this.$t("page_faqs_table_header_display_order"),
          align: "center",
          value: "order"
        },
        {
          text: this.$t("actions"),
          align: "center",
          value: "actions",
          sortable: false
        }
      ],
      loading: false,
      deleteItemDlg: false,
      formStatus: false
    };
  },

  methods: {
    updateTable(e) {
      if (!this.initialLoad) this.getDataFromApi(e);
    },
    async getDataFromApi(e = undefined) {
      this.loading = true;
      let data = {};
      if (e?.sortBy !== undefined && e?.sortDesc !== undefined) {
        data.order = e.sortDesc[0] ? "desc" : "asc";
        data.sort = e.sortBy[0];
      } else {
        data.sort = "id";
        data.order = "desc";
      }
      if (e?.page !== undefined && e?.paginate !== undefined) {
        data.page = e.page;
        data.paginate = e.paginate;
      }
      await this.$store.dispatch("FAQ_GET_ALL", data);
      this.loading = false;
      this.initialLoad = false;
    },

    deleteItemConfirm() {
      this.deleteItemDlg = false;
      this.formStatus = true;
      this.$store.dispatch("FAQ_DELETE", this.deleteItem).then(() => {
        this.faqs.splice(this.faqs.indexOf(this.deleteItem), 1);
        this.formStatus = false;
      });
    }
  }
};
</script>
