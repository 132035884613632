var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-toolbar', {
    attrs: {
      "flat": "",
      "color": "transparent",
      "height": "40"
    }
  }, [_c('v-toolbar-title', [_c('h3', {
    staticClass: "headline mb-0 font-weight-bold main--text"
  }, [_vm._v(" FAQ ")])]), _c('v-spacer'), _c('v-row', {
    staticClass: "flex-row-reverse"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-weight-regular",
    attrs: {
      "small": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "secondary",
      "left": ""
    }
  }, [_vm._v("$goBack")]), _vm._v(" " + _vm._s(_vm.$t("go_back")) + " ")], 1)], 1)], 1)], 1)], 1), _vm._t("content")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }