<template>
  <v-row>
    <v-col cols="12">
      <v-toolbar flat color="transparent" height="40">
        <v-toolbar-title>
          <h3 class="headline mb-0 font-weight-bold main--text">
            FAQ
          </h3>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-row class="flex-row-reverse">
          <v-col cols="auto">
            <v-btn
              small
              class="text-capitalize font-weight-regular"
              text
              @click="$router.go(-1)"
            >
              <v-icon color="secondary" left>$goBack</v-icon>
              {{ $t("go_back") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
    </v-col>

    <slot name="content"></slot>
  </v-row>
</template>

<script>
export default {
  name: "index",
  data() {
    return {};
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
